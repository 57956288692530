import React from "react";
const Container = ({ children }) => {
  return (
    <div
      style={{
        maxWidth: "960px",
        margin: "0 auto",
        padding: "0 24px",
        position: "relative",
      }}
    >
      {children}
    </div>
  );
};

export default Container;
