import React from "react";
const Logo = () => {
  return (
    <svg viewBox="0 0 34 9" style={{height: "30px", marginRight: "auto", display: "block"}}>
    <path d="M2.5949 4.94752H3.52161H6.10507H7.02563V5.97991H6.47911H3.14693H2.5949V4.94752Z" fill="white"/>
    <path d="M14.5035 3.52203L13.375 3.71263C13.3278 3.57497 13.2529 3.44394 13.1502 3.31953C13.0502 3.19511 12.9142 3.09319 12.7421 3.01378C12.5699 2.93436 12.3548 2.89466 12.0966 2.89466C11.744 2.89466 11.4498 2.9701 11.2138 3.12099C10.9778 3.26923 10.8598 3.46115 10.8598 3.69674C10.8598 3.90057 10.939 4.0647 11.0972 4.18911C11.2554 4.31353 11.5108 4.41545 11.8634 4.49486L12.8795 4.71722C13.468 4.84693 13.9067 5.04679 14.1954 5.3168C14.4841 5.58681 14.6285 5.93756 14.6285 6.36904C14.6285 6.73435 14.5174 7.05995 14.2953 7.34584C14.076 7.62908 13.7692 7.85144 13.375 8.01292C12.9836 8.1744 12.5297 8.25513 12.0133 8.25513C11.2971 8.25513 10.7127 8.10954 10.2602 7.81836C9.80768 7.52452 9.53006 7.1076 9.42735 6.56758L10.6308 6.39287C10.7058 6.69199 10.8598 6.91832 11.093 7.07186C11.3262 7.22275 11.6302 7.29819 12.005 7.29819C12.4131 7.29819 12.7393 7.21745 12.9836 7.05598C13.2279 6.89185 13.35 6.69199 13.35 6.4564C13.35 6.2658 13.2751 6.10565 13.1252 5.97594C12.978 5.84623 12.7518 5.74829 12.4464 5.68211L11.3637 5.45577C10.7668 5.32606 10.3254 5.11959 10.0395 4.83634C9.75632 4.5531 9.61474 4.19441 9.61474 3.76028C9.61474 3.40026 9.72023 3.08525 9.93122 2.81524C10.1422 2.54523 10.4337 2.33479 10.8057 2.1839C11.1777 2.03036 11.6038 1.9536 12.0841 1.9536C12.7754 1.9536 13.3195 2.09654 13.7165 2.38243C14.1135 2.66568 14.3758 3.04555 14.5035 3.52203Z" fill="white"/>
    <path d="M18.9832 2.03301V2.98598H15.4894V2.03301H18.9832ZM16.4264 2.66039H17.6715V6.34125C17.6715 6.57155 17.7076 6.74494 17.7797 6.86141C17.8519 6.97524 17.9449 7.05333 18.0587 7.09568C18.1753 7.13539 18.3017 7.15524 18.4377 7.15524C18.5376 7.15524 18.6251 7.14863 18.7 7.13539C18.775 7.12215 18.8333 7.11157 18.8749 7.10363L19.0998 8.08439C19.0276 8.11086 18.9249 8.13734 18.7916 8.16381C18.6584 8.19293 18.4918 8.20881 18.2919 8.21146C17.9644 8.21675 17.659 8.16116 17.3758 8.04469C17.0926 7.92821 16.8636 7.74821 16.6887 7.50467C16.5138 7.26113 16.4264 6.95539 16.4264 6.58743V2.66039Z" fill="white"/>
    <path d="M23.0485 8.25513C22.4183 8.25513 21.8756 8.12675 21.4203 7.86997C20.9678 7.61055 20.618 7.24657 20.3709 6.77803C20.1266 6.30683 20.0045 5.7549 20.0045 5.12223C20.0045 4.49751 20.1266 3.9469 20.3709 3.47041C20.618 2.99393 20.9622 2.622 21.4037 2.35464C21.8478 2.08728 22.367 1.9536 22.9611 1.9536C23.322 1.9536 23.6718 2.01051 24.0105 2.12434C24.3491 2.23816 24.6531 2.41685 24.9224 2.66039C25.1917 2.90392 25.4041 3.22026 25.5595 3.60939C25.715 3.99587 25.7927 4.46574 25.7927 5.019V5.43989H20.7082V4.55045H24.5726C24.5726 4.23809 24.506 3.96146 24.3727 3.72057C24.2395 3.47703 24.0521 3.28511 23.8106 3.14481C23.5718 3.00451 23.2914 2.93437 22.9694 2.93437C22.6196 2.93437 22.3142 3.01643 22.0533 3.18055C21.7951 3.34203 21.5952 3.5538 21.4536 3.81587C21.3148 4.07529 21.2454 4.35721 21.2454 4.66163V5.35651C21.2454 5.76417 21.3204 6.11094 21.4703 6.39684C21.623 6.68273 21.8353 6.90112 22.1074 7.05201C22.3795 7.20025 22.6973 7.27437 23.061 7.27437C23.297 7.27437 23.5121 7.2426 23.7065 7.17907C23.9008 7.11289 24.0688 7.01495 24.2103 6.88524C24.3519 6.75553 24.4602 6.59537 24.5352 6.40478L25.7136 6.60728C25.6192 6.93818 25.4499 7.22804 25.2056 7.47687C24.9641 7.72306 24.6601 7.91498 24.2936 8.05263C23.9299 8.18763 23.5149 8.25513 23.0485 8.25513Z" fill="white"/>
    <path d="M29.0356 8.26705C28.6303 8.26705 28.2639 8.19557 27.9363 8.05263C27.6087 7.90703 27.3491 7.69659 27.1576 7.42128C26.9688 7.14598 26.8744 6.80847 26.8744 6.40875C26.8744 6.06462 26.9438 5.78137 27.0826 5.55901C27.2214 5.33665 27.4088 5.16062 27.6448 5.03091C27.8808 4.9012 28.1445 4.80325 28.436 4.73707C28.7275 4.6709 29.0245 4.6206 29.3271 4.58619C29.7102 4.54383 30.0212 4.50942 30.2599 4.48295C30.4987 4.45383 30.6722 4.4075 30.7804 4.34397C30.8887 4.28044 30.9428 4.1772 30.9428 4.03426V4.00646C30.9428 3.65968 30.8401 3.391 30.6347 3.2004C30.432 3.00981 30.1294 2.91451 29.7269 2.91451C29.3077 2.91451 28.9773 3.00319 28.7358 3.18055C28.4971 3.35526 28.3319 3.54983 28.2403 3.76425L27.0701 3.51012C27.2089 3.13952 27.4116 2.84039 27.6781 2.61274C27.9474 2.38243 28.2569 2.21566 28.6067 2.11242C28.9565 2.00654 29.3244 1.9536 29.7102 1.9536C29.9656 1.9536 30.2363 1.98271 30.5223 2.04095C30.811 2.09654 31.0803 2.19978 31.3301 2.35067C31.5828 2.50156 31.7896 2.7173 31.9506 2.9979C32.1116 3.27585 32.1921 3.63718 32.1921 4.0819V8.13204C31.3627 8.13204 30.9762 7.75085 30.9762 7.29819H30.9262C30.8457 7.45173 30.7249 7.60261 30.5639 7.75085C30.4029 7.89909 30.1961 8.02219 29.9434 8.12013C29.6908 8.21807 29.3882 8.26705 29.0356 8.26705ZM29.3063 7.31407C29.6506 7.31407 29.9448 7.24922 30.1891 7.11951C30.4362 6.9898 30.6236 6.82038 30.7513 6.61126C30.8818 6.39948 30.947 6.17315 30.947 5.93226V5.14606C30.9026 5.18841 30.8165 5.22812 30.6888 5.26518C30.5639 5.29959 30.4209 5.33004 30.2599 5.35651C30.0989 5.38033 29.942 5.40283 29.7894 5.42401C29.6367 5.44254 29.509 5.45842 29.4062 5.47166C29.1647 5.50078 28.944 5.54975 28.7441 5.61857C28.547 5.6874 28.3888 5.78667 28.2694 5.91638C28.1528 6.04344 28.0945 6.21286 28.0945 6.42463C28.0945 6.71847 28.2083 6.94083 28.436 7.09171C28.6636 7.23995 28.9537 7.31407 29.3063 7.31407Z" fill="white"/>
    <path d="M1 8.13204H2.36587L3.14693 5.97991H2.5949V4.94752H3.52161L5.3571 2.83122e-07H4.06903L1 8.13204Z" fill="white"/>
    <path d="M7.25883 8.13204H8.62469L7.42286 4.94752H7.02563V5.97991H6.47911L7.25883 8.13204Z" fill="white"/>
    <path d="M5.06593 3.94061H7.04775L6.05008 1.29613L5.06593 3.94061Z" fill="white"/>
    </svg>


  );
};

export default Logo;
