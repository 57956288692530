import React from "react";
import { Link } from "gatsby";

const style = {
  borderRadius: "8px",
  color: "#fff",
  background: "#1D3820",
  padding: "16px 32px",
  display: "inline-flex",
  justifyContent: "center",
  gap: "8px",
  boxShadow:
    "inset #1D3820 0 0 0 1px, inset 0px 19px 29px rgba(155, 255, 155, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.07), 0px 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 20px 20px -7px rgba(0, 0, 0, 0.2), inset 0px -14px 20px rgba(0, 0, 0, 0.1)",
};

const Container = ({ children, link, to, onClick }) => {
  return (
    <>
      {link ? (
        <Link className="button" to={to} style={style}>
          {children}
          <svg style={{ width: "18px" }} viewBox="0 0 19 18">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.70245 2.73401C10 2.39394 10.5169 2.35948 10.857 2.65704L17.4024 8.38431C17.58 8.53967 17.6818 8.76412 17.6818 9.00006C17.6818 9.23599 17.58 9.46044 17.4024 9.6158L10.857 15.3431C10.5169 15.6406 10 15.6062 9.70245 15.2661C9.40489 14.926 9.43935 14.4091 9.77942 14.1116L15.6212 9.00006L9.77942 3.88853C9.43935 3.59097 9.40489 3.07407 9.70245 2.73401Z"
              fill="currentColor"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.31836 8.99982C1.31836 8.54795 1.68467 8.18164 2.13654 8.18164H16.8638C17.3157 8.18164 17.682 8.54795 17.682 8.99982C17.682 9.45169 17.3157 9.818 16.8638 9.818H2.13654C1.68467 9.818 1.31836 9.45169 1.31836 8.99982Z"
              fill="currentColor"
            />
          </svg>
        </Link>
      ) : (
        <button onClick={onClick} className="button" style={style}>
          {children}
          <svg style={{ width: "18px" }} viewBox="0 0 19 18">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.70245 2.73401C10 2.39394 10.5169 2.35948 10.857 2.65704L17.4024 8.38431C17.58 8.53967 17.6818 8.76412 17.6818 9.00006C17.6818 9.23599 17.58 9.46044 17.4024 9.6158L10.857 15.3431C10.5169 15.6406 10 15.6062 9.70245 15.2661C9.40489 14.926 9.43935 14.4091 9.77942 14.1116L15.6212 9.00006L9.77942 3.88853C9.43935 3.59097 9.40489 3.07407 9.70245 2.73401Z"
              fill="currentColor"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.31836 8.99982C1.31836 8.54795 1.68467 8.18164 2.13654 8.18164H16.8638C17.3157 8.18164 17.682 8.54795 17.682 8.99982C17.682 9.45169 17.3157 9.818 16.8638 9.818H2.13654C1.68467 9.818 1.31836 9.45169 1.31836 8.99982Z"
              fill="currentColor"
            />
          </svg>
        </button>
      )}
    </>
  );
};

export default Container;
