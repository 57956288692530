import * as React from "react";
import Layout from "../comps/Layout";
import Hero from "../comps/Hero";
import Stack from "../comps/Stack";
import Input from "../comps/Input";
import Button from "../comps/Button";
import Section from "../comps/Section";
import SectionHeading from "../comps/SectionHeading";

// markup
const IndexPage = () => {
  const [error, setError] = React.useState(false);
  return (
    <Layout> 
      <title>Investerare - Astea</title>

      <Hero text="Investerare" bg="/omoss3.png" />
      <Section>
        <SectionHeading>Logga in</SectionHeading>
        <div style={{ maxWidth: "300px", margin: "auto" }}>
          <Stack v mobile>
            {error && <div className="error">Kontrollera att du angivit rätt användarnamn och/eller lösenord.</div>}
            <Input label="användarnamn" />
            <Input type="password" label="lösenord" />
            <Button onClick={() => setError(true)}>Logga in</Button>
          </Stack>
        </div>
      </Section>
    </Layout>
  );
};

export default IndexPage;
