import React from "react";

const Input = ({ type = "text", label = "label" }) => {
  return (
    <>
      <label
        className="label"
        style={{ textAlign: "left", marginBottom: "8px" }}
      >
        {label}
      </label>
      <input
        type={type}
        style={{
          transition: ".3s",
          marginBottom: "16px",
          borderRadius: "8px",
          appearance: "none",
          border: 0,
          boxShadow: "rgba(0,0,0,.1) 0 0 0 1px",
          background: "#fff",
          height: "16px",
          padding: "16px",
          fontSize: "1rem",
          fontFamily: "'inter', sans-serif",
        }}
      />
    </>
  );
};

export default Input;
