import React, { useState, useEffect } from "react";
import Container from "./Container";
import Stack from "./Stack";
import Logo from "./Logo";
import { Link } from "gatsby";

const Nav = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <nav className={`nav ${scrolled && "scrolled "} ${open && " open"}`} >
        <Container>
          <div className="inner"  style={{color: "#fff"}}>
            <Link to="/">
              <Logo />
            </Link>
            <div
              onClick={() => setOpen(!open)}
              style={{
                width: "16px",
                height: "16px",
                cursor: "pointer",
                transform: open ? "scale(1.3) " : "scale(1.2)",
                transition: ".3s",
              }}
            >
              <div
                style={{
                  backgroundColor: "#fff",
                  height: "1px",
                  transform: open
                    ? "translateY(6px) rotate(-45deg) scaleY(2) "
                    : "scaleY(2)",
                  marginBottom: "5px",
                  transition: ".3s",
                }}
              />
              <div
                style={{
                  backgroundColor: "#fff",
                  height: "1px",
                  transform: open ? "scale(0, 2)" : "scale(1, 2)",
                  transformOrigin: "100% 50%",
                  opacity: open ? "0" : "1",
                  marginBottom: "5px",
                  transition: ".3s",
                }}
              />
              <div
                style={{
                  backgroundColor: "#fff",
                  height: "1px",
                  transform: open
                    ? "translateY(-6px) rotate(45deg) scaleY(2) "
                    : "scaleY(2)",
                  marginBottom: "5px",
                  transition: ".3s",
                }}
              />
            </div>
          </div>
        </Container>
      </nav>
      <menu
        className={`menu ${open && "open"}`}
      >
        <div className="menu-inner">
          <Stack v gap="24">
            <Link to="/">Start</Link>
            <Link to="/kontakt">Kontakt</Link>
            <Link to="/invest">Investerare</Link>
          </Stack>
        </div>
      </menu>
    </>
  );
};

export default Nav;
