import React, { useEffect, useState } from "react";
const Hero = ({ bg, tall, text }) => {
  const [scroll, setScroll] = useState(0);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div
      style={{
        height: tall ? "80vh" : "40vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <img
        src={bg}
        style={{
          objectFit: "cover",
          position: "absolute",
          width: "100%",
          height: "100%",
          transform: `translateY(${scroll * 0.75}px)`,
        }}
      />
      <h1
        style={{
          zIndex: 1,
          transform: `translateY(${scroll * 0.5}px)`,
          opacity: 1 - scroll * (tall ? 0.001 : 0.005),
        }}
      >
        {text}
      </h1>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          zIndex: 0,
          right: 0,
          backgroundColor: "rgba(0,0,0,.3)",
          backgroundImage: "linear-gradient(rgba(0,0,0,.2), transparent)",
        }}
      />
    </div>
  );
};

export default Hero;
