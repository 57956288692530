import React from "react";
import Nav from "./Nav";
import Section from "./Section";
import Stack from "./Stack";
import {Link} from "gatsby";
import "./layout.css";
import Logo from "./Logo";

const Layout = ({ children }) => {
  return (
    <main
      style={{
        overflow: "hidden",
      }}
    >
      <Nav />
      {children}
      <div className="footer" style={{color: "#fff"}}>
        <Section bg="#243B27">
          <Stack between>
            <Stack v mobile gap={16}>
              <Logo />
              <span>Copyright Astea 2023.</span>
            </Stack>
            <Stack gap={96}  style={{flexGrow: "0"}} >
              <Stack mobile v style={{flexGrow: "0"}} gap={16}>
                <span className="label" style={{textAlign: "left"}}>Meny</span>
                <Link to="/">Start</Link> 
                <Link to="/kontakt">Kontakt</Link>
                <Link to="/invest">Investerare</Link>
              </Stack>
              <Stack mobile v style={{flexGrow: "0"}}  gap={16}>
                <span className="label" style={{textAlign: "left"}}>Kontakt</span>
                <span>kontakt@astea.se</span>
                <span>08-546 01 749</span>
                <span>Nybrogatan 6</span>
                <span>114 34 Stockholm</span>
              </Stack>
            </Stack>
          </Stack>
       </Section>
      </div>
    </main>
  ); 
};

export default Layout;
