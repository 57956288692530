import React from "react";

const Stack = ({ children, v, gap = 0, between, around, vcenter, grow = true, mobile = false, style }) => {
  return (
    <div
      className={`stack ${v && "v"} ${grow && "grow"} ${mobile && "mobile"}`}
      style={{
        ...style,
        ...{display: "flex",
        flexDirection: v ? "column" : "row",
        gap: gap + "px",
        justifyContent: around ? "space-around" : between
          ? "space-between"
          : vcenter
          ? "center"
          : "flex-start",}
      }}
    >
      {children}
    </div>
  );
};

export default Stack;
